
<template>
  <div>

    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 400px;
        background-image: url(img/mail.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <span class="mask bg-gradient-green opacity-5"></span>

      <span class="mask bg-gradient-green opacity-5"></span>
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12 col-md-10">
            <h1 class="display-2 text-white">Search for freight</h1>
            <p class="text-white mt-0 mb-0">
            </p>
          </div>
        </div>
      </div>

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <freight-table title="Freight"></freight-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FreightTable from "./Tables/FreightTable";
import store from '../store';

function safeNull(val) {
  if (val) {
    return val.toString();
  }
  return "0";
}

export default {
  name: "freight",
  components: {
    FreightTable,
  },
  computed: {
  }
};
</script>
<style></style>
