import { createStore } from "vuex";

export default createStore({
    state : function() {
        return {
            userName : "",
            promises : {
                userSession : null,
                userName : null,
                groupName : null,
                credentials : null,
                packagesData : null, // will get rid of 

                ordersList : null,
            },
            ordersQuery : null,
            ordersQueryProperties : null, // various columns asc or desc sort

            clientConnections : {
                ddb : null
            },

            // will get rid of 
            new30Days : "loading",
            numberOfPackages : "loading",
            delivered : "loading",
            issues : "loading",
            trackStats : {
                processing : "loading",
                labeled : "loading",
                inTransit : "loading",
                delivered : "loading",
                clearance : "loading",
                delay : "loading",
            },
            notificationStats : {
                emailSent : "loading",
            }
        }
    },
    getters: {
        getUserName(state) {
            return state.userName;
        },
        // promise related
        getUserSessionPromise(state) {
            return state.promises.userSession;
        },
        getUserNamePromise(state) {
            return state.promises.userName;
        },
        getGroupNamePromise(state) {
            return state.promises.groupName;
        },
        getCredentialsPromise(state) {
            return state.promises.credentials;
        },
        getDdbPromise(state) {
            return state.clientConnections.ddb;
        },
    },
    mutations: {
        setUserName(state, newUserName) {
            state.userName = newUserName;
        },
        setUserSessionPromise(state, userSession) {
            state.promises.userSession = userSession;
        },
        setUserNamePromise(state, userName) {
            state.promises.userName = userName;
        },
        setGroupNamePromise(state, groupName) {
            state.promises.groupName = groupName;
        },
        setCredentialsPromise(state, credentials) {
            state.promises.credentials = credentials;
        },
        setPackagesDataPromise(state, packagesData) {
            state.promises.packagesData = packagesData;
        },
        setOrdersListPromise(state, ordersList) {
            state.promises.ordersList = ordersList;
        },
        setDdbPromise(state, ddb) {
            state.clientConnections.ddb = ddb;
        },
    }
});