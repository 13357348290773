<template>
  <div>

    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 400px;
        background-image: url(img/mail.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <span class="mask bg-gradient-green opacity-5"></span>

      <span class="mask bg-gradient-green opacity-5"></span>
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12 col-md-10">
            <h1 class="display-2 text-white">Send notification </h1>
            <p class="text-white mt-0 mb-0">
            </p>
          </div>
        </div>
      </div>

      <!-- Card stats -->
      <!--div class="row">
        <div class="col-xl-3 col-lg-6">
          <count-card
            title="Email Sent"
            type="default"
            v-bind:sub-title="emailSent"
            class="mb-2 mb-sm-0"
            what="packages whose tracking email has been sent"
          >
          </count-card>
        </div>
      </div-->


    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <notifications-table title="Notifications"></notifications-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NotificationsTable from "./Tables/NotificationsTable";
import store from '../store';

function safeNull(val) {
  if (val) {
    return val.toString();
  }
  return "0";
}

export default {
  name: "notifications",
  components: {
    NotificationsTable,
  },
  computed: {
    emailSent : () => {
      return safeNull(store.getters.getEmailSent);
    },
  }
};
</script>
<style></style>
