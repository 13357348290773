<template>
  <div>

    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 400px;
        background-image: url(img/theme/airplane-takeoff-2.png);
        background-size: cover;
        background-position: center top;
      "
    >
      <span class="mask bg-gradient-green opacity-7"></span>
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12 col-md-10">
            <h1 class="display-2 text-white">Search for orders</h1>
            <p class="text-white mt-0 mb-0">
            </p>
          </div>
        </div>
      </div>

      <!-- Card stats -->
      <!--div class="row">
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="Processing"
            type="default"
            v-bind:sub-title="processing"
            class="mb-2 mb-sm-0"
            what="Is currently being processed by the system if doesn't become 0 after some time please contact system administrator"
          >
          </count-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="Labeled"
            type="default"
            v-bind:sub-title="labeled"
            class="mb-4 mb-xl-0"
            what="Label created not yet shipped"
          >
          </count-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="In Transit"
            type="info"
            v-bind:sub-title="inTransit"
            class="mb-4 mb-xl-0"
            what="Picked up by carrier currently in transit"
          >
          </count-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="Delivered"
            type="success"
            v-bind:sub-title="delivered"
            class="mb-4 mb-xl-0"
            what="Successfully delivered to receipient"
          >
          </count-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="Clearance"
            type="warning"
            v-bind:sub-title="clearance"
            class="mb-4 mb-xl-0"
            what="Undergoing clearance"
          >
          </count-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="Delay"
            type="danger"
            v-bind:sub-title="delay"
            class="mb-4 mb-xl-0"
            what="Shipment stuck or returning"
          >
          </count-card>
        </div>
      </div-->

      <!--div class="row mt-4">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Search</h3>
                  </div>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div-->

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <packages-table title="Tracking Packages"></packages-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PackagesTable from "./Tables/PackagesTable";
import store from '../store';

function safeNull(val) {
  if (val) {
    return val.toString();
  }
  return "0";
}

export default {
  name: "packages",
  components: {
    PackagesTable,
  },
  computed: {
    processing : () => {
      return safeNull(store.getters.getTrackProcessing);
    },
    labeled : () => {
      return safeNull(store.getters.getTrackLabeled);
    },
    inTransit : () => {
      return safeNull(store.getters.getTrackInTransit);
    },
    delivered : () => {
      return safeNull(store.getters.getTrackDelivered);
    },
    clearance : () => {
      return safeNull(store.getters.getTrackClearance);
    },
    delay : () => {
      return safeNull(store.getters.getTrackDelay);
    },
  }
};
</script>
<style></style>
