<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 400px;
        background-image: url(img/theme/chip.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <span class="mask bg-gradient-green opacity-7"></span>

      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-10 col-md-10">
            <h1 class="display-2 text-white">Settings page</h1>
            <p class="text-white mt-0 mb-0">
              You can find the various option settings for D31 here.
              Please click save after making changes.
            </p>
            <!--a href="#!" class="btn btn-info">Edit profile</a-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <!--div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Notification Options</h3>
                  </div>
                  <div class="col-4 text-right">
                    <a href="#!" class="btn btn-sm btn-primary">Save</a>
                  </div>
                </div>
              </div>
            </template>
            </card>
        </div-->

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">Email settings</h3>
                  </div>
                  <div class="col-4 text-right">
                    <base-button size="sm" 
                        @mouseup="save()"
                        :disabled="saveDisabled"
                    >{{ saveButtonMessage }}</base-button>
                  </div>
                </div>
              </div>
            </template>

            <base-input type="text" alternative="" class="form-control form-control-alternative" label="Sender">
                <textarea
                      rows="1"
                      class="form-control form-control-alternative"
                      v-model="sender"
                    />
            </base-input>

            <base-input type="text" alternative="" class="form-control form-control-alternative" label="Test Receipient">
                <textarea
                      rows="1"
                      class="form-control form-control-alternative"
                      v-model="testReceipient"
                    />
            </base-input>

            <div v-for="template in emailTemplates" :key="template.id">
              <hr class="my-4" />
              <base-input type="text" alternative="" class="form-control form-control-alternative" label="Template Name">
                <textarea
                      rows="1"
                      class="form-control form-control-alternative"
                      v-model="template.name"
                    />
              </base-input>

              <base-input type="text" alternative="" class="form-control form-control-alternative" label="Subject">
                <textarea
                      rows="1"
                      class="form-control form-control-alternative"
                      v-model="template.subject"
                    />
              </base-input>

              <base-input type="text" alternative="" class="form-control form-control-alternative" label="Body">
                <textarea
                      rows="10"
                      class="form-control form-control-alternative"
                      v-model="template.body"
                    />
              </base-input>

            </div>

            <div class="row">
              <div class="col text-left">
                <base-button size="sm" 
                  @mouseup="newTemplate()"
                  :disabled="saveDisabled"
                >New Template</base-button>
              </div>
              <div class="col text-right">
                <small>version : {{ version }}</small>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { ref } from "vue";
import { onBeforeMount } from '@vue/runtime-core';

import data from "../data";
import store from "../store";
import config from "../config";
import axios from 'axios';

async function updateSettingsRequest(userSessionPromise, params) {
  var jwtToken = await userSessionPromise.then(session => {
    return session.credentials.idToken;
  })
  var ep = config.SaveSettingsEndpoint;
  const headers = { 
    "Authorization": jwtToken
  };
  return axios.post(ep, params, { headers })
    .then(response => { 
      return response.data;
    }).catch(err => {
      console.log("unable to send save request", err);
      return null;
    })
}

export default {
  name: "settings",
  data() {
    return {
      saveDisabled : false,
      saveButtonMessage : "save",
    };
  },
  setup() {
    const sender = ref("sender");
    const testReceipient = ref("test receipient");
    const version = ref("");
    const emailTemplates = ref([]);
    onBeforeMount(async () => {
      // first query should be from create_time descending
      let companyName = await store.getters.getGroupNamePromise;
      let query = {
          ExpressionAttributeValues: {
              ':company' : {S: companyName},
          },
          KeyConditionExpression: 'company_id = :company',
          TableName: 'd31-' + config.stage + '-company-settings',
      };

      let ddb = await store.getters.getDdbPromise;
      let settings = await ddb.query(query).promise();
      let s = settings["Items"][0];
      let emailSettings = s["email_settings"].M;
      sender.value = emailSettings.sender_email_address.S;
      testReceipient.value = emailSettings.test_receipient_email_address.S;

      let templates = emailSettings.email_templates.M;
      let id = 0;
      for (var name in templates) {
        let template = templates[name].M;
        emailTemplates.value.push({
          "id" : id,
          "name" : name,
          "subject" : template.subject.S,
          "body" : template.body.S,
        })
        id++;
      }
      version.value = s.version.N;
    });
    return {
      sender,
      testReceipient,
      emailTemplates,
      version,
    }
  },
  methods : {
    async save() {
      this.saveButtonMessage = "saving";
      this.saveDisabled = true;
      let emailTemplates = {};
      this.emailTemplates.forEach(function(template, index, array) {
        emailTemplates[template.name] = {
          "subject" : template.subject,
          "body" : template.body
        }
      });
      let params = {
        "email_settings" : {
          "email_templates" : emailTemplates,
          "sender_email_address" : this.sender,
          "test_receipient_email_address" : this.testReceipient,
        }
      };
      console.log(params);
      let resp = await updateSettingsRequest(this.$store.getters.getUserSessionPromise, params);
      if (resp.status) {
        this.version = resp.version;
        this.saveButtonMessage = "save";
        this.saveDisabled = false;
      }
    },
    newTemplate() {
      let id = this.emailTemplates.length;
      let newName = "template" + (this.emailTemplates.length + 1);
      this.emailTemplates.push({
        "id" : id,
        "name" : newName,
        "subject" : "subject",
        "body" : "body"
      })
    }
  }
};
</script>
<style></style>
