<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center" v-if="!searchExpand">
        <div class="col" @click="searchExpandToggle">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            Click here to search
            &nbsp;
            <i class="ni ni-bold-right"></i> 
          </h3>
        </div>
      </div>
      <div class="row align-items-center" v-else>
        <form class="form-inline">
          <div class="form-group mx-sm-3">
            <base-input>
                <select class="form-control form-control-sm" v-model="searchParams.field"
                  @change="fieldSelected">
                  <option>order id</option>
                  <option>status</option>
                  <option>email sent</option>
                  <option>create time</option>
                </select>
            </base-input>
          </div>
          <div class="form-group mx-sm-3" v-if="searchParams.field != 'email sent'">
            <base-input>
              <select class="form-control form-control-sm" v-model="searchParams.operator">
                <option>=</option>
                <option>&lt;</option>
                <option>&leq;</option>
                <option>&gt;</option>
                <option>&geq;</option>
                <option>between</option>
                <option>begins with</option>
                <option>don't care</option>
              </select>
            </base-input>
          </div>
          <div class="form-group mx-sm-3" v-if="searchParams.field != 'email sent' && searchParams.operator != 'don\'t care'">
            <input class="form-control form-control-sm" type="text" 
              v-model="searchParams.value1"
              @keyup.enter="search"
              :key="searchParams.fieldSelect"
              v-bind:placeholder="searchParams.textPlaceholder"
              />
          </div>
          <div class="form-group mx-sm-3" v-if="searchParams.field != 'email sent' && searchParams.operator == 'between'">
            <input class="form-control form-control-sm" type="text" 
              v-model="searchParams.value2"
              @keyup.enter="search"
              :key="searchParams.fieldSelect"
              v-bind:placeholder="searchParams.textPlaceholder"
              />
          </div>
          <div class="form-group mx-sm-3" v-if="searchParams.field != 'email sent'">
            <base-button icon type="secondary" size="sm" @mouseup="sortChange" v-if="searchParams.sortAsc == true"
              >
              sort 
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
              </svg>
            </base-button>
            <base-button icon type="secondary" size="sm" @mouseup="sortChange" v-else
              >
              sort 
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </base-button>
          </div>
          <div class="form-group form-check-inline mx-sm-3" v-else>
             <input class="form-check-input" type="radio" id="yes" name="emailSent" value="yes" v-model="searchParams.emailSent">
             <label class="form-check-input" for="yes"><small>yes</small></label><br>
             <input class="form-check-input" type="radio" id="no" name="emailSent" value="no" v-model="searchParams.emailSent">
             <label class="form-check-input" for="no"><small>no</small></label><br>
          </div>
          <div class="form-group mx-sm-3">
            <base-button size="sm" type="primary" 
                @mouseup="search"
                v-bind:disabled="buttonsDisable"
                :key="buttonKeys.search"
                >Search</base-button>
          </div>
          <div class="form-group mx-sm-3">
            <base-button size="sm" type="primary" 
                @mouseup="clear"
                v-bind:disabled="buttonsDisable"
                :key="buttonKeys.clear"
                >Clear</base-button>
          </div>
        </form>
      </div>

      <br/>

      <div class="row align-items-center">
        <form class="form-inline">
          <div class="form-group mx-sm-3">
            <base-input>
              <select class="form-control form-control-sm" v-model="templateName">
                <option v-for="name in templateNames" :key="name">{{ name }}</option>
              </select>
            </base-input>
          </div>

          <base-button size="sm" type="default" 
                  @mouseup="sendEmail" v-bind:disabled="buttonsDisable"
                  id="test"
                  :key="buttonKeys.testEmail"
                  >{{ testButtonMsg }}
          </base-button>
          <base-button size="sm" type="default" 
                  @mouseup="sendEmail" v-bind:disabled="buttonsDisable"
                  id="send"
                  :key="buttonKeys.sendEmail"
                  >{{ sendEmailButtonMsg }}
          </base-button>
        </form>

        <div class="col text-right">
          <base-button size="sm" type="default" 
                  @mouseup="save" v-bind:disabled="buttonsDisable"
                  :key="buttonKeys.save"
                  >{{ saveButtonMsg }}
          </base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="searchMode ? searchList : ordersList"
      >
        <template v-slot:columns>
          <th><input type="checkbox" id="checkAll" v-model="selectAll" @click="selectAllClick"></th>
          <th id="title" @click="columnClick">Order ID
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'titleAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'titleDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th id="status" @click="columnClick">Status
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'statusAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'statusDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th id="customerName" @click="columnClick">Customer Name
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'customerNameAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'customerNameDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th id="customerEmail" @click="columnClick">Email Address
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'customerEmailAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'customerEmailDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th id="lastEmailedDate" @click="columnClick">Email Sent
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'customerEmailAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'customerEmailDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th id="createdDateIsoFormat" @click="columnClick">Created Date
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'createdDateIsoFormatAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'createdDateIsoFormatDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th id="statusMessage" @click="columnClick">Status Message
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'statusMessageAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'statusMessageDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th></th>
        </template>

        <template v-slot:default="row">

          <td @click="rowToggleClick" v-bind:id="row.item.id">
            <input type="checkbox" v-bind:value="row.item.id" v-model="selectOrders">
          </td>

          <th scope="row" @click="rowToggleClick" v-bind:id="row.item.id">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.title }}</span>
              </div>
            </div>
          </th>

          <td @click="rowToggleClick" v-bind:id="row.item.id">
            <!-- statusType are warning, success, danger, info, success -->
            <badge class="badge-dot mr-0" :type="row.item.statusType">
              <i :class="`bg-${row.item.statusType}`"></i>
              <span class="status">{{ row.item.status }}</span>
            </badge>
          </td>

          <td @click="rowClick" v-bind:id="row.item.id">
            <input
              class="form-control form-control-sm"
              v-model="row.item.customerName"
              />
          </td>

          <td @click="rowClick" v-bind:id="row.item.id">
            <input
              class="form-control form-control-sm"
              v-model="row.item.customerEmail"
              />
          </td>

          <td @click="rowToggleClick" v-bind:id="row.item.id">
              <i class="ni ni-check-bold" v-if="row.item.lastEmailedDate != ''"></i>
          </td>

          <!--td @click="rowToggleClick" v-bind:id="row.item.id">
            {{ row.item.lastEmailedDate }}
          </td-->

          <td style="white-space:pre-wrap; word-wrap:break-word" 
            @click="rowToggleClick" v-bind:id="row.item.id">
            {{ row.item.createdDate }}
          </td>

          <td @click="rowToggleClick" v-bind:id="row.item.id">
            {{ row.item.statusMessage }}
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
      v-if="(!searchMode && status != 'COMPLETE') || (searchMode && searchStatus != 'COMPLETE')"
    >
      <div class="row align-items-center">
        <div class="col text-center">
          <h4 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            <div v-if="(searchMode ? searchStatus : status) == 'LOAD_MORE'">
              <base-button outline type="secondary" @mouseup="loadMore">Click to load more</base-button>
              <base-button outline type="secondary" @mouseup="loadAll">Click to load all</base-button>
            </div>
            <div v-else-if="(searchMode ? searchStatus : status) == 'LOADING'">
              Loading
            </div>
            <div v-else-if="(searchMode ? searchStatus : status) == 'LOADING_ALL'">
              Loading all please wait
            </div>
            <div v-else-if="searchMode && searchStatus == 'NOT_FOUND'">
              Search results not found
            </div>
          </h4>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { ref } from "vue";
import { onBeforeMount } from '@vue/runtime-core';

import data from "../../data";
import store from "../../store";
import config from "../../config";
import axios from 'axios';

function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
}

async function sendNotificationRequest(userSessionPromise, params) {
  var jwtToken = await userSessionPromise.then(session => {
    return session.credentials.idToken;
  })
  var ep = config.SendNotificationEndpoint;
  const headers = { 
    "Authorization": jwtToken
  };
  const orders = {
    'orders' : params
  }
  return axios.post(ep, orders, { headers })
    .then(response => { 
      return response.data;
    }).catch(err => {
      console.log("unable to send update request", err);
      return null;
    })
}

export default {
  name: "notifications-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  setup() {
    const ordersList = ref([]);
    const status = ref("LOADING");
    const ordersQuery = ref();
    const ordersPromise = ref();
    const templateNames = ref([]);
    const testReceipient = ref([]);
    const testButtonMsg = ref("Test");
    const templateName = ref("");
    onBeforeMount(async () => {
      // first query should be from create_time descending
      let companyName = await store.getters.getGroupNamePromise
      ordersQuery.value = {
          ExpressionAttributeValues: {
              ':company' : {S: companyName},
          },
          KeyConditionExpression: 'company_id = :company',
          TableName: 'd31-' + config.stage + '-packages',
          Limit: config.DbLoadGranularityRead,
          ScanIndexForward : false, // descending
          IndexName : "company_id-create_time-index"
      };

      let ddb = await store.getters.getDdbPromise;
      ordersPromise.value = ddb.query(ordersQuery.value).promise();

      let orders = await ordersPromise.value
      if ('LastEvaluatedKey' in orders) {
        status.value = "LOAD_MORE";
      } else {
        status.value = "COMPLETE";
      }
      orders.Items.forEach(function(item, index, array) {
        ordersList.value.push(data.itemToRow(item, index));
      });

      // search template names
      let templateNamesQuery = {
          ExpressionAttributeValues: {
              ':company' : {S: companyName},
          },
          KeyConditionExpression: 'company_id = :company',
          TableName: 'd31-' + config.stage + '-company-settings',
      };

      let settings = await ddb.query(templateNamesQuery).promise();
      let s = settings["Items"][0];
      let emailSettings = s["email_settings"].M;
      let templates = emailSettings.email_templates.M;
      for (var name in templates) {
        templateNames.value.push(name);
      }
      testReceipient.value = emailSettings.test_receipient_email_address.S;
      testButtonMsg.value += " Send : " + testReceipient.value;
      templateName.value = templateNames.value[0];
    })
    return {
      ordersList, 
      status,
      ordersQuery,
      ordersPromise,
      templateNames,
      testReceipient,
      testButtonMsg,
      templateName,
    }
  },
  data() {
    return {
      selectAll: false,
      selectOrders: [],
      searchExpand : false,
      searchMode : false,
      searchStatus : "COMPLETE",
      searchList : [],
      searchQuery : null,
      searchPromise : null,
      searchParams : {
        field : "order id",
        fieldSelect : 0,
        operator : "=",
        value1 : "",
        value2 : "",
        textPlaceholder: "order1 ...",
        sortAsc : false,
        emailSent : "no",
      },
      columnSort : "createdDateIsoFormatDesc",
      saveButtonMsg : "Save",
      buttonsDisable : false,
      sendEmailButtonMsg : "Send",
      buttonKeys : {
        search : 0,
        clear : 0,
        sendEmail: 0,
        testEmail: 0,
        save: 0,
      }
    };
  },
  methods : {
    searchExpandToggle() {
      this.searchExpand = !this.searchExpand;
    },
    selectAllClick() {
      this.selectOrders = []
      if (!this.selectAll) {
        let arr = this.searchMode ? this.searchList : this.ordersList;
        arr.forEach(order => {
          let id = order.id;
          this.selectOrders.push(id);
        })
      }
    },
    fieldSelected() {
      this.searchParams.fieldSelect++;
      data.updateSearchParamPlaceholder(this.searchParams);
    },
    clear() {
      this.selectOrders = [];
      this.searchMode = false;
    },
    async search() {
      data.search(this, "packages");
    },
    async loadMore() {
      data.loadMore(this);
    },
    getStatus() {
      if (this.searchMode) {
        return this.searchStatus;
      } else {
        return this.status;
      }
    },
    setStatus(status) {
      if (this.searchMode) {
        this.searchStatus = status;
      } else {
        this.status = status;
      }
    },
    async loadAll() {
      // call loadMore() every second until status is changed
      this.setStatus("LOADING_ALL");
      do {
        this.loadMore();
        if (this.getStatus() == "COMPLETE") {
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      } while (this.getStatus() != "COMPLETE");
    },
    async sendEmail(event) {
      let id = event.currentTarget.id;
      this.buttonsDisable = true;
      this.sendEmailButtonMsg = "Sending";
      for (let keys in this.buttonKeys) {
        this.buttonKeys[keys]++;
      }

      let emailOverride = id == "test" ? this.testReceipient : "";
      for (const i in this.selectOrders) {
        let id = this.selectOrders[i];
        let row = this.searchMode ? this.searchList[id] : this.ordersList[id];
        let orderId = row.title;
        let customerName = row.customerName;
        let customerEmail = emailOverride ? emailOverride : row.customerEmail;

        if (!validateEmail(customerEmail)) {
          row.statusMessage = "invalid email address";
          continue;
        }

        let params = [
            { 
              id : id,
              orderId : orderId,
              customerName : customerName,
              customerEmail : customerEmail,
              trackingNumber : row.trackingNumber,
              carrier : row.carrier,
              templateName : this.templateName,
              test : emailOverride ? true : false,
            }
          ]

        let res = await sendNotificationRequest(this.$store.getters.getUserSessionPromise, params);

        row.statusMessage = "sending";
        if (!res) {
          row.statusMessage = "error";
          continue;
        }
        let resi = res[id];

        let emailSentTime = "";
        if ('emailSentTime' in resi) {
          emailSentTime = data.utcTimeToLocal(resi.emailSentTime);
        }
        row.statusMessage = resi.statusMessage;
        row.lastEmailedDate = emailSentTime;
      }

      this.buttonsDisable = false;
      this.sendEmailButtonMsg = "Send";
      for (let keys in this.buttonKeys) {
        this.buttonKeys[keys]++;
      }
    },
    async save() {
      this.buttonsDisable = true;
      this.saveButtonMsg = "Saving...";
      for (let keys in this.buttonKeys) {
        this.buttonKeys[keys]++;
      }

      for (const i in this.selectOrders) {
        let id = this.selectOrders[i];
        let row = this.searchMode ? this.searchList[id] : this.ordersList[id];
        let orderId = row.title;
        let customerName = row.customerName;
        let customerEmail = row.customerEmail;

        let params = [
            { 
              id : id,
              orderId : orderId,
              customerName : customerName,
              customerEmail : customerEmail,
            }
          ]

        row.statusMessage = "saving";
        let res = await data.updateOrderInfoRequest(this.$store.getters.getUserSessionPromise, params);
        if (!res) {
          row.statusMessage = "error";
          continue;
        }
        let resi = res[id];
        row.statusMessage = resi.statusMessage;
      }

      this.buttonsDisable = false;
      this.saveButtonMsg = "Save";
      for (let keys in this.buttonKeys) {
        this.buttonKeys[keys]++;
      }
    },
    rowToggleClick(event) {
      let id = event.currentTarget.id;
      if (!this.selectOrders.includes(id)) {
        this.selectOrders.push(id);
      } else {
        let filtered = this.selectOrders.filter(function(value, index, arr) {
          return value != id;
        });
        this.selectOrders = filtered;
      }
    },
    rowClick(event) {
      let id = event.currentTarget.id;
      if (!this.selectOrders.includes(id)) {
        this.selectOrders.push(id);
      }
    },
    columnClick(event) {
      let id = event.currentTarget.id;
      let direction = "Desc";
      if (this.columnSort.startsWith(id)) {
        if (this.columnSort.endsWith(direction)) {
          // then change to asc
          direction = "Asc";
        }
      }
      this.columnSort = id + direction;
      let l = this.searchMode ? this.searchList : this.ordersList;
      l.sort(function(a, b) {
        if (a[id] > b[id]) {
          return direction == "Desc" ? -1 : 1;
        }
        return direction == "Desc" ? 1 : -1;
      });
    },
    sortChange() {
      this.searchParams.sortAsc = !this.searchParams.sortAsc;
    }
  }
};
</script>
<style></style>
