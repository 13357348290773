/* eslint-disable */
import {CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js'

import AWS from 'aws-sdk';
import config from "../config";

import store from '../store';
import data from '../data';

function getCognitoSession (userPool, cognitoUser) {
    return new Promise((resolve, reject) => {
        cognitoUser.getSession((err, result) => {
            if (err || !result) {
                reject(new Error('Failure getting Cognito session: ' + err))
                return
            }

            // Resolve the promise with the session credentials
            //console.debug('Successfully got session: ' + JSON.stringify(result))

            var groupName = "";
            if ('cognito:groups' in result.idToken.payload) {
                groupName = result.idToken.payload['cognito:groups'][0];
            }

            const session = {
                credentials: {
                    accessToken: result.accessToken.jwtToken,
                    idToken: result.idToken.jwtToken,
                    refreshToken: result.refreshToken.token
                },
                user: {
                    userName: result.idToken.payload['cognito:username'],
                    groupName: groupName,
                    email: result.idToken.payload.email
                }
            }
            resolve(session)
        })
    })
}

var userPool = new CognitoUserPool({
    UserPoolId: config.CognitoUserPoolId,
    ClientId: config.CognitoClientId,
});

function init() {
    let currentUser = userPool.getCurrentUser();
    if (!currentUser) {
        // not logged in, no need to initialize
        console.log("not logged in, skipping initializaton");
        return;
    }

    var userSession = getCognitoSession(userPool, currentUser);
    store.commit("setUserSessionPromise", userSession);

    var userName = userSession.then(session => {
        return session.user.userName;
    });
    store.commit("setUserNamePromise", userName);

    var groupName = userSession.then(session => {
        return session.user.groupName;
    });
    store.commit("setGroupNamePromise", groupName);

    var cognitoCredentials = userSession.then(session => {
        var idToken = session.credentials.idToken;
        var logins = {}
        logins['cognito-idp.' + config.region + '.amazonaws.com/' + config.CognitoUserPoolId] = idToken
        AWS.config.region = config.region;
        var cognitoCredentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.CognitoIdentityPoolId,
            Logins: logins
        }, { region : config.region });
        AWS.config.credentials = cognitoCredentials
        return cognitoCredentials;
    });

    store.commit("setCredentialsPromise", cognitoCredentials);

    data.init(groupName, cognitoCredentials);
}

function deinit() {
    data.deinit();
    var credentials = store.getters.getCredentialsPromise;
    if (credentials) {
        credentials.then(cognitoCredentials => {
            cognitoCredentials.clearCachedId();
        })
    }
    store.commit("setCredentialsPromise", null);
    store.commit("setGroupNamePromise", null);
    store.commit("setUserNamePromise", null);
    store.commit("setUserSessionPromise", null);
    userNewPassword = null;
}

var userNewPassword = null

function authenticate (username, pass) {
    let authenticationData = { Username: username, Password: pass }
    let authenticationDetails = new AuthenticationDetails(authenticationData)
    let userData = { Username: username, Pool: userPool }
    let user = new CognitoUser(userData)

    let promise = new Promise(function(resolve, reject) {
      user.authenticateUser(authenticationDetails, {
        onSuccess: resolve,
        onFailure: reject,
        newPasswordRequired: resolve
      }); /**/
    });

    promise.then((result) => {
        if (!(result && 'idToken' in result)) {
            delete result.email_verified // Not accepted by the challenge call
            delete result.phone_number_verified // Also not accepted
            userNewPassword = user;
        }
      });

    return promise;
}

function completeNewPasswordChallenge (newPass) {
    return new Promise(function(resolve, reject) {
        userNewPassword.completeNewPasswordChallenge(newPass, {}, {
            onSuccess : resolve,
            onFailure : reject,
        });
    });
}

init();

export default {
    userPool : userPool,
    init: init,
    deinit: deinit,
    authenticate: authenticate,
    completeNewPasswordChallenge: completeNewPasswordChallenge,
}