<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center" @click="searchExpandToggle" v-if="!searchExpand">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            Click here to search
            &nbsp;
            <i class="ni ni-bold-right"></i> 
          </h3>
        </div>
      </div>
      <div class="row align-items-center" v-else>
        <form class="form-inline">
          <div class="form-group mx-sm-3">
            <base-input>
                <select class="form-control form-control-sm" v-model="searchParams.field"
                  @change="fieldSelected">
                  <option>order id</option>
                  <option>status</option>
                  <option>tracking number</option>
                  <option>create time</option>
                </select>
            </base-input>
          </div>
          <div class="form-group mx-sm-3">
            <base-input>
              <select class="form-control form-control-sm" v-model="searchParams.operator"
                @change="fieldSelected">
                <option>=</option>
                <option>&lt;</option>
                <option>&leq;</option>
                <option>&gt;</option>
                <option>&geq;</option>
                <option>between</option>
                <option v-if="searchParams.field != 'create time'">begins with</option>
                <option>don't care</option>
              </select>
            </base-input>
          </div>
          <div class="form-group mx-sm-3">
            <input class="form-control form-control-sm" type="text" v-if="searchParams.operator != 'don\'t care'"
              v-model="searchParams.value1"
              @keyup.enter="search"
              :key="buttonKeys.fieldSelect"
              v-bind:disabled="buttonsDisable"
              v-bind:placeholder="searchParams.textPlaceholder"
              />
          </div>
          <div class="form-group mx-sm-3" v-if="searchParams.operator == 'between'">
            <input class="form-control form-control-sm" type="text" 
              v-model="searchParams.value2"
              @keyup.enter="search"
              :key="buttonKeys.fieldSelect"
              v-bind:disabled="buttonsDisable"
              v-bind:placeholder="searchParams.textPlaceholder"
              />
          </div>
          <div class="form-group mx-sm-3">
            <base-button icon type="secondary" size="sm" @mouseup="sortChange" v-if="searchParams.sortAsc == true"
              >
              sort 
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
              </svg>
            </base-button>
            <base-button icon type="secondary" size="sm" @mouseup="sortChange" v-else
              >
              sort 
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </base-button>
          </div>
          <div class="form-group mx-sm-3">
            <base-button size="sm" type="primary" 
                @mouseup="search"
                v-bind:disabled="buttonsDisable"
                :key="buttonKeys.search"
                >Search</base-button>
          </div>
          <div class="form-group mx-sm-3">
            <base-button size="sm" type="primary" 
                @mouseup="clear"
                v-bind:disabled="buttonsDisable"
                :key="buttonKeys.clear"
                >Clear</base-button>
          </div>
        </form>
      </div>

      <br/>

      <div class="row align-items-center">
        <div class="col text-left">
          <small>
            <input type="checkbox" v-model="checkboxStatusDetail" id="checkboxStatusDetail">
              &nbsp;
            <label for="checkboxStatusDetail">Show status details</label>
          </small>
        </div>

        <div class="col text-right">
          <base-button size="sm" type="default" 
                  @mouseup="deleteOrders" 
                  v-bind:disabled="buttonsDisable"
                  :key="buttonKeys.delete"
                  >{{ deleteButtonMsg }}
          </base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="searchMode ? searchList : ordersList"
      >
        <template v-slot:columns>
          <th><input type="checkbox" id="checkAll" v-model="selectAll" @click="selectAllClick"></th>
          <th id="title" @click="columnClick">Order ID
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'titleAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'titleDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th id="status" @click="columnClick">Status
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'statusAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'statusDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>

          <th id="statusDetails" v-if="checkboxStatusDetail">Status Details
          </th>

          <th id="trackingNumber" @click="columnClick">Tracking Number
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'trackingNumberAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'trackingNumberDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th id="lastUpdatedDateIsoFormat" @click="columnClick">Last Updated Date
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'lastUpdatedDateIsoFormatAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'lastUpdatedDateIsoFormatDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th id="createdDateIsoFormat" @click="columnClick">Created Date
            &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"
               v-if="columnSort == 'createdDateIsoFormatAsc'">
              <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"
              v-else-if="columnSort == 'createdDateIsoFormatDesc'">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16"
              v-else>
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
          </th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <td @click="rowToggleClick" v-bind:id="row.item.id" v-if="!row.item.deleted">
            <input type="checkbox" v-bind:value="row.item.id" v-model="selectOrders">
          </td>
          <th scope="row" @click="rowToggleClick" v-bind:id="row.item.id" v-if="!row.item.deleted">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.title }}</span>
              </div>
            </div>
          </th>
          <td :title="row.item.statusDetails " @click="rowToggleClick" v-bind:id="row.item.id" v-if="!row.item.deleted">
            <!-- statusType are warning, success, danger, info, success -->
            <badge class="badge-dot mr-0" :type="row.item.statusType">
              <i :class="`bg-${row.item.statusType}`"></i>
              <span class="status">{{ row.item.status }}</span>
            </badge>
          </td>
          <td style="white-space:pre-wrap; word-wrap:break-word" v-if="checkboxStatusDetail">{{ row.item.statusDetail }}
          </td>
          <td class="trackingNumber" v-if="!row.item.deleted">
            <div class="media align-items-center">
              <a :href="row.item.carrierLink" class="avatar rounded-circle mr-3">
                <img alt="" :src="row.item.img" />
              </a>
              <div class="media-body">
                <a :href="row.item.carrierLink" 
                    style="text-decorations:none; color:inherit"
                    target="_blank" rel="noopener noreferrer"
                    >
                  <span class="name mb-0 text-sm">{{ row.item.trackingNumber }}</span>
                </a>
              </div>
            </div>
          </td>
          <td style="white-space:pre-wrap; word-wrap:break-word" 
            @click="rowToggleClick" v-bind:id="row.item.id" v-if="!row.item.deleted">
            {{ row.item.lastUpdatedDate }}
          </td>
          <td style="white-space:pre-wrap; word-wrap:break-word" 
            @click="rowToggleClick" v-bind:id="row.item.id" v-if="!row.item.deleted">
            {{ row.item.createdDate }}
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
      v-if="(!searchMode && status != 'COMPLETE') || (searchMode && searchStatus != 'COMPLETE')"
    >
      <div class="row align-items-center">
        <div class="col text-center">
          <h4 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            <div v-if="(searchMode ? searchStatus : status) == 'LOAD_MORE'">
              <base-button outline type="secondary" @mouseup="loadMore">Click to load more</base-button>
              <base-button outline type="secondary" @mouseup="loadAll">Click to load all</base-button>
            </div>
            <div v-else-if="(searchMode ? searchStatus : status) == 'LOADING'">
              Loading
            </div>
            <div v-else-if="(searchMode ? searchStatus : status) == 'LOADING_ALL'">
              Loading all please wait
            </div>
            <div v-else-if="searchMode && searchStatus == 'NOT_FOUND'">
              Search results not found
            </div>
            <div v-else-if="searchMode && searchStatus.length > 0">
              {{ searchStatus }}
            </div>
          </h4>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import { ref } from "vue";
import { onBeforeMount } from '@vue/runtime-core';

import data from "../../data";
import store from "../../store";
import config from "../../config";

export default {
  name: "freight-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  methods : {
    fieldSelected() {
      this.buttonKeys.fieldSelect++;
      data.updateSearchParamPlaceholder(this.searchParams);
    },
    async search() {
      data.search(this, "freight");
    },
    clear() {
      this.searchMode = false;
    },
    searchExpandToggle() {
      this.searchExpand = !this.searchExpand;
    },
    async loadMore() {
      data.loadMore(this);
    },
    getStatus() {
      if (this.searchMode) {
        return this.searchStatus;
      } else {
        return this.status;
      }
    },
    setStatus(status) {
      if (this.searchMode) {
        this.searchStatus = status;
      } else {
        this.status = status;
      }
    },
    async loadAll() {
      // call loadMore() every second until status is changed
      this.setStatus("LOADING_ALL");
      do {
        this.loadMore();
        if (this.getStatus() == "COMPLETE") {
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      } while (this.getStatus() != "COMPLETE");
    },
    selectAllClick() {
      this.selectOrders = []
      if (!this.selectAll) {
        let arr = this.searchMode ? this.searchList : this.ordersList;
        arr.forEach(order => {
          let id = order.id;
          this.selectOrders.push(id);
        })
      }
    },
    rowClick(event) {
      let id = event.currentTarget.id;
      if (!this.selectOrders.includes(id)) {
        this.selectOrders.push(id);
      }
    },
    rowToggleClick(event) {
      let id = event.currentTarget.id;
      if (!this.selectOrders.includes(id)) {
        this.selectOrders.push(id);
      } else {
        let filtered = this.selectOrders.filter(function(value, index, arr) {
          return value != id;
        });
        this.selectOrders = filtered;
      }
    },
    columnClick(event) {
      let id = event.currentTarget.id;
      let direction = "Desc";
      if (this.columnSort.startsWith(id)) {
        if (this.columnSort.endsWith(direction)) {
          // then change to asc
          direction = "Asc";
        }
      }
      this.columnSort = id + direction;
      let l = this.searchMode ? this.searchList : this.ordersList;
      l.sort(function(a, b) {
        if (a[id] > b[id]) {
          return direction == "Desc" ? -1 : 1;
        }
        return direction == "Desc" ? 1 : -1;
      });
    },
    sortChange() {
      this.searchParams.sortAsc = !this.searchParams.sortAsc;
    },
    async deleteOrders() {
      this.buttonsDisable = true;
      this.deleteButtonMsg = "Deleting...";
      for (let keys in this.buttonKeys) {
        if (keys == "fieldSelect") {
          continue;
        }
        this.buttonKeys[keys]++;
      }

      for (const i in this.selectOrders) {
        let id = this.selectOrders[i];
        let row = this.searchMode ? this.searchList[id] : this.ordersList[id];
        let orderId = row.title;
        let params = [
            { 
              id : id,
              orderId : orderId,
              delete : true,
            }
          ]

        let res = await data.updateOrderInfoRequest(this.$store.getters.getUserSessionPromise, params);
        if (!res) {
          continue;
        }
        row.deleted = true;
      }

      this.buttonsDisable = false;
      this.deleteButtonMsg = "Delete";
      for (let keys in this.buttonKeys) {
        if (keys == "fieldSelect") {
          continue;
        }
        this.buttonKeys[keys]++;
      }
    }
  },
  setup() {
    const ordersList = ref([]);
    const status = ref("LOADING");
    const ordersQuery = ref();
    const ordersPromise = ref();
    onBeforeMount(async () => {
      // first query should be from create_time descending
      let companyName = await store.getters.getGroupNamePromise
      ordersQuery.value = {
          ExpressionAttributeValues: {
              ':company' : {S: companyName},
          },
          KeyConditionExpression: 'company_id = :company',
          TableName: 'd31-' + config.stage + '-freight',
          Limit: config.DbLoadGranularityRead,
          ScanIndexForward : false, // descending
          IndexName : "company_id-create_time-index"
      };

      let ddb = await store.getters.getDdbPromise;
      ordersPromise.value = ddb.query(ordersQuery.value).promise();

      let orders = await ordersPromise.value
      if ('LastEvaluatedKey' in orders) {
        status.value = "LOAD_MORE";
      } else {
        status.value = "COMPLETE";
      }
      orders.Items.forEach(function(item, index, array) {
        ordersList.value.push(data.itemToRow(item, index));
      });
    })
    return {
      ordersList, 
      status,
      ordersQuery,
      ordersPromise,
    }
  },
  data() {
    return {
      searchExpand : false,
      searchMode : false,
      searchStatus : "COMPLETE",
      searchList : [],
      searchQuery : null,
      searchPromise : null,
      searchParams : {
        field : "order id",
        operator : "=",
        value1 : "",
        value2 : "",
        textPlaceholder: "order1 ...",
        sortAsc : false,
      },
      columnSort : "createdDateIsoFormatDesc",
      deleteButtonMsg : "Delete",
      buttonsDisable : false,
      selectAll: false,
      selectOrders: [],
      buttonKeys : {
        fieldSelect : 0,
        delete : 0,
        search : 0,
        clear : 0,
      },
      checkboxStatusDetail : false,
    };
  },
  mounted() {
    let query = this.$route.query;
    if ("status" in query) {
      this.searchExpand = true;
      this.searchMode = true;
      this.searchParams.field = "status";
      this.searchParams.operator = "=";
      this.searchParams.value1 = query.status;
      data.search(this, "freight");
    }
  }
};
</script>
<style></style>
