<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <!--div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign in with</small>
          </div>
          <div class="btn-wrapper text-center">
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/github.svg"
              /></span>
              <span class="btn-inner--text">Github</span>
            </a>
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/google.svg"
              /></span>
              <span class="btn-inner--text">Google</span>
            </a>
          </div>
        </div-->
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small v-if="!codeSent">Enter username or email address to get verification code for registered email</small>
            <small v-if="codeSent">Code has been sent please use it to complete</small>
          </div>
          <form role="form" @submit.prevent="buttonPress">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Username or Email"
              addon-left-icon="ni ni-email-83"
              v-model:value="model.email"
              name="email"
            >
            </base-input>

            <div v-if="codeSent">
              <base-input
                formClasses="input-group-alternative mb-3"
                placeholder="Code"
                addon-left-icon="ni ni-key-25"
                v-model:value="model.code"
                name="code"/>
              <base-input
                formClasses="input-group-alternative mb-3"
                placeholder="New Password"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
                v-model:value="model.password"
                name="password"/>
              <base-input
                formClasses="input-group-alternative mb-3"
                placeholder="New Password Confirm"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
                v-model:value="model.passwordConfirm"
                name="passwordConfirm"/>
            </div>
            <!--base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox-->

            <div class="text-left" v-if="errorMessage.length > 0">
              <p class="text-danger"><small>{{ errorMessage }}</small></p>
            </div>

            <div class="text-center">
              <base-button native-type="submit" type="primary" class="my-4">{{ buttonMessage }}</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="#/forgot" class="text-light"><small>Forgot password?</small></a>
        </div>
        <!--div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div-->
      </div>
    </div>
  </div>
</template>
<script>
import { CognitoUser } from 'amazon-cognito-identity-js'

import auth from '../auth';
import router from '../router';


export default {
  name: "forgot",
  data() {
    return {
      model : {
        email: "",
        code: "",
        password: "",
        passwordConfirm: "",
      },
      buttonMessage : "Send Verification Code",
      errorMessage : "",
      codeSent : false,
    };
  },
  methods: {
    buttonPress: function(event) {

      var userName = event.target.elements.email.value;
      var userData = {
        Username: userName,
        Pool: auth.userPool,
      };
      var cognitoUser = new CognitoUser(userData);
          
      if (this.codeSent == false) {
        this.codeSent = true;
        this.buttonMessage = "Verify Code";
        cognitoUser.forgotPassword({
	       onSuccess: function(data) {
	       	// successfully initiated reset password request
	       	console.log('CodeDeliveryData from forgotPassword: ' + data);
	       },
	       onFailure: function(err) {
	       	console.log(err.message);
	       },
	       //Optional automatic callback
	       inputVerificationCode: function(data) {
	       	console.log('Code sent');
	       },
        });
        return;
      }

      // now to confirm new password
	    var verificationCode = event.target.elements.code.value;
	    var newPassword = event.target.elements.password.value;
	    var newPasswordConfirm = event.target.elements.passwordConfirm.value;

      if (newPassword != newPasswordConfirm) {
        this.errorMessage = "passwords do not match";
        return;
      }
	    cognitoUser.confirmPassword(verificationCode, newPassword, {
	    	onSuccess() {
          auth.authenticate(userName, newPassword).then(result => {
            if ('idToken' in result) {
                auth.init();
                router.push('/');
            }});
        } 
	    });
    },
  }
};
</script>
<style></style>
