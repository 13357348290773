<template>
  <div>
    <!--base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8"-->
    
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      style="
        min-height: 400px;
        background-image: url(img/theme/datacenter.jpg);
        background-size: cover;
        background-position: center top;
      "
    >
      <span class="mask bg-gradient-blue opacity-7"></span>

      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <p class="text-white">Last updated : {{ basicStats.lastUpdated }}</p>
        </div>
      </div>

      <br>

      <div class="row">
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="Labeled"
            type="default"
            v-bind:sub-title="basicStats.labeled"
            class="mb-4 mb-xl-0"
            what="Label created not yet shipped"
            @click="gotoStatus"
            id="labeled"
          >
          </count-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="In Transit"
            type="info"
            v-bind:sub-title="basicStats.transit"
            class="mb-4 mb-xl-0"
            what="Picked up by carrier currently in transit"
            @click="gotoStatus"
            id="transit"
          >
          </count-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="Delivered"
            type="success"
            v-bind:sub-title="basicStats.delivered"
            class="mb-4 mb-xl-0"
            what="Successfully delivered to receipient"
            @click="gotoStatus"
            id="delivered"
          >
          </count-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="Clearance"
            type="warning"
            v-bind:sub-title="basicStats.clearance"
            class="mb-4 mb-xl-0"
            what="Undergoing clearance"
            @click="gotoStatus"
            id="clearance"
          >
          </count-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="Delay"
            type="danger"
            v-bind:sub-title="basicStats.delay"
            class="mb-4 mb-xl-0"
            what="Shipment stuck or returning"
            @click="gotoStatus"
            id="delay"
          >
          </count-card>
        </div>
        <div class="col-xl-2 col-lg-6">
          <count-card
            title="Processing"
            type="default"
            v-bind:sub-title="basicStats.processing"
            class="mb-2 mb-sm-0"
            what="Is currently being processed by the system if doesn't become 0 after some time please contact system administrator"
            @click="gotoStatus"
            id="processing"
          >
          </count-card>
        </div>
      </div>

      <br>
      <div class="row">
        <!--div class="col-xl-2 col-lg-6">
          <count-card
            title="Archived"
            type="default"
            v-bind:sub-title="basicStats.archived"
            class="mb-4 mb-xl-0"
            what="Undergoing clearance"
          >
          </count-card>
        </div-->

        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Tracking"
            type="gradient-red"
            v-bind:sub-title="basicStats.tracking"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>

        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Archived"
            type="gradient-red"
            v-bind:sub-title="basicStats.archived"
            class="mb-4 mb-xl-0"
          >
          </stats-card>
        </div>

        <!--div class="col-xl-3 col-lg-6">
          <stats-card
            title="Total traffic"
            type="gradient-orange"
            sub-title="2,356"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> 12.18%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Sales"
            type="gradient-green"
            sub-title="924"
            icon="ni ni-money-coins"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-danger mr-2">
                <i class="fa fa-arrow-down"></i> 5.72%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Performance"
            type="gradient-info"
            sub-title="49,65%"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0"
          >
            <template v-slot:footer>
              <span class="text-success mr-2">
                <i class="fa fa-arrow-up"></i> 54.8%
              </span>
              <span class="text-nowrap">Since last month</span>
            </template>
          </stats-card>
        </div-->
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!--Charts-->
      <div class="row">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-light text-uppercase ls-1 mb-1">Tracking over time</h6>
                  <h5 class="h3 text-white mb-0">Orders being tracked</h5>
                </div>

                <div class="col">
                  <ul class="nav nav-pills justify-content-end">
                    <div class="form-group form-check-inline mx-sm-3">
                      <input class="form-check-input" type="radio" id="7days" name="emailSent" value="7days" v-model="timeSpan">
                      <label class="form-check-input" for="7days"><small>7days</small></label><br>
                      <input class="form-check-input" type="radio" id="14days" name="emailSent" value="14days" v-model="timeSpan">
                      <label class="form-check-input" for="14days"><small>14days</small></label><br>
                      <input class="form-check-input" type="radio" id="30days" name="emailSent" value="30days" v-model="timeSpan">
                      <label class="form-check-input" for="30days"><small>30days</small></label><br>
                    </div>
                  </ul>
                </div>
                <!--div class="col">
                  <ul class="nav nav-pills justify-content-end">
                    <li class="nav-item mr-2 mr-md-0">
                      <a
                        class="nav-link py-2 px-3"
                        href="#"
                        :class="{ active: bigLineChart.activeIndex === 0 }"
                        @click.prevent="initBigChart(0)"
                      >
                        <span class="d-none d-md-block">7 days</span>
                        <span class="d-md-none">M</span>
                      </a>
                    </li>
                    <li class="nav-item mr-2 mr-md-0">
                      <a
                        class="nav-link py-2 px-3"
                        href="#"
                        :class="{ active: bigLineChart.activeIndex === 1 }"
                        @click.prevent="initBigChart(0)"
                      >
                        <span class="d-none d-md-block">14 days</span>
                        <span class="d-md-none">M</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link py-2 px-3"
                        href="#"
                        :class="{ active: bigLineChart.activeIndex === 2 }"
                        @click.prevent="initBigChart(1)"
                      >
                        <span class="d-none d-md-block">30 days</span>
                        <span class="d-md-none">W</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link py-2 px-3"
                        href="#"
                        :class="{ active: bigLineChart.activeIndex === 3 }"
                        @click.prevent="initBigChart(1)"
                      >
                        <span class="d-none d-md-block">all</span>
                        <span class="d-md-none">W</span>
                      </a>
                    </li>
                  </ul>
                </div-->
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="timelineID"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Not yet delivered
                  </h6>
                  <h5 class="h3 mb-0">Create to now</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="c2nID"></canvas>
            </div>
          </card>
        </div>
      </div>

      <br>
      <div class="row">
        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Delivered
                  </h6>
                  <h5 class="h3 mb-0">
                    Create to delivered time
                    </h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="c2dID"></canvas>
            </div>
          </card>
        </div>
        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">
                    Delivered
                  </h6>
                  <h5 class="h3 mb-0">Pickup to delivered time</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :height="350" :id="p2dID"></canvas>
            </div>
          </card>
        </div>
      </div>

      <!-- End charts-->

      <!--Tables-->
      <!--div class="row mt-5">
        <div class="col-xl-8 mb-5 mb-xl-0">
          <page-visits-table></page-visits-table>
        </div>
        <div class="col-xl-4">
          <social-traffic-table></social-traffic-table>
        </div>
      </div-->
      <!--End tables-->
    </div>
  </div>
</template>
<script>
// Charts
import { ordersChart } from "@/components/Charts/Chart";
import Chart from "chart.js";

//import PageVisitsTable from "./Dashboard/PageVisitsTable";
//import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
let chart;

import { ref } from "vue";
import { onBeforeMount } from '@vue/runtime-core';

import data from "../data";
import store from "../store";
import config from "../config";

function createHistograms(item, h, timespan, label) {
  let days = item.times.M[timespan]
  let bins = days.M[h + '_bins'].L.map(x => x.S);
  let vals = days.M[h + '_vals'].L.map(x => parseFloat(x.N));
  let N = Math.min(bins.length, vals.length);
  if (bins.length != N) {
    bins = bins.slice(0, N);
  }
  if (vals.length != N) {
    vals = vals.slice(0, N);
  }
  return ordersChart.createChart2(h, bins, vals, label);
}

function updateHistograms(hist, h, item, timespan) {
  let days = item.times.M[timespan]
  let bins = days.M[h + '_bins'].L.map(x => x.S);
  let vals = days.M[h + '_vals'].L.map(x => parseFloat(x.N));
  let N = Math.min(bins.length, vals.length);
  if (bins.length != N) {
    bins = bins.slice(0, N);
  }
  if (vals.length != N) {
    vals = vals.slice(0, N);
  }
  hist.data.labels = bins;
  hist.data.datasets[0].data = vals;
  hist.update();
}

function updateTimelineChart(chart, chartId, item, name) {
  let timeline = item.timeline.M[name].M;
  let labels = timeline.t.L;
  let status_stats = timeline.avg.L;

  let labelsStr = []
  let processingData = []
  let labeledData = []
  let transitData = []
  let deliveredData = []
  let clearanceData = []
  let delayData = []
  for (let i = 0; i < labels.length; i++) {
    let t = new Date(labels[i].S);
    labelsStr.push((t.getMonth() + 1) + "/" + t.getDate()); // + " " + t.getHours());

    processingData.push(parseFloat(status_stats[i].M.processing.N));
    labeledData.push(parseFloat(status_stats[i].M.labeled.N));
    transitData.push(parseFloat(status_stats[i].M.transit.N));
    deliveredData.push(parseFloat(status_stats[i].M.delivered.N));
    clearanceData.push(parseFloat(status_stats[i].M.clearance.N));
    delayData.push(parseFloat(status_stats[i].M.delay.N));
  }

  if (chart != null) {
    chart.destroy();
  }

  return ordersChart.createChart3(chartId, labelsStr, labeledData, transitData,
      deliveredData, clearanceData, delayData);
}

export default {
  components: {
  },
  data() {
    return {
      timeSpan : "7days",
      salesChartID: "salesChart",
      ordersChartID: "ordersChart",
      timelineID: "timeline",
      c2nID : "c2n",
      c2dID : "c2d",
      p2dID : "p2d",
    };
  },
  watch : {
    timeSpan : function(newValue) {
      let c2n = this.histograms['c2n'];
      let c2d = this.histograms['c2d'];
      let p2d = this.histograms['p2d'];
      updateHistograms(c2n, "c2n", this.statsItem, newValue);
      updateHistograms(c2d, "c2d", this.statsItem, newValue);
      updateHistograms(p2d, "p2d", this.statsItem, newValue);
      this.timeline = updateTimelineChart(this.timeline, "timeline", this.statsItem, newValue);
    }
  },
  setup() {
    const basicStats = ref({
      processing : "0",
      labeled : "0",
      transit : "0",
      delay : "0",
      clearance : "0",
      delivered : "0",
      archived : "0",
      tracking : "0",
      lastUpdated : "loading",
    });
    const statsItem = ref();
    const histograms = ref({});
    const timeline = ref();
    onBeforeMount(async () => {
      // first query should be from create_time descending
      let companyName = await store.getters.getGroupNamePromise
      let statsQuery = {
          ExpressionAttributeValues: {
              ':company' : {S: companyName},
          },
          KeyConditionExpression: 'company_id = :company',
          TableName: 'd31-' + config.stage + '-company-stats',
          Limit: config.DbLoadGranularityRead,
      };

      let ddb = await store.getters.getDdbPromise;
      let stats = await ddb.query(statsQuery).promise();
      let item = stats.Items[0];
      let statusCount = item.status_count.M;
      basicStats.value.archived = item.archived.N;
      basicStats.value.tracking = item.tracking.N;

      basicStats.value.processing = statusCount.processing.N;
      basicStats.value.labeled = statusCount.labeled.N;
      basicStats.value.transit = statusCount.transit.N;
      basicStats.value.delay = statusCount.delay.N;
      basicStats.value.clearance = statusCount.clearance.N;
      basicStats.value.delivered = statusCount.delivered.N;

      histograms.value['c2n'] = createHistograms(item, "c2n", "7days", "not yet delivered");
      histograms.value['c2d'] = createHistograms(item, "c2d", "7days", "create to delivered");
      histograms.value['p2d'] = createHistograms(item, "p2d", "7days", "pickup to delivered");

      statsItem.value = item;

      basicStats.value.lastUpdated = data.utcTimeToLocal(item.create_time.S);

      timeline.value = updateTimelineChart(null, "timeline", item, "7days")
    });
    return {
      basicStats, 
      statsItem,
      histograms,
      timeline,
    }
  },
  methods: {
    gotoStatus(event) {
      let status = event.currentTarget.id;
      // with query, resulting in /register?plan=private
      this.$router.push({ path: 'packages', query: { status: status }})
    }
  },
  mounted() {
  },
};
</script>
<style></style>
