<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <!--div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign in with</small>
          </div>
          <div class="btn-wrapper text-center">
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/github.svg"
              /></span>
              <span class="btn-inner--text">Github</span>
            </a>
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/google.svg"
              /></span>
              <span class="btn-inner--text">Google</span>
            </a>
          </div>
        </div-->
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Sign in with credentials</small>
          </div>
          <form role="form" @submit.prevent="signin">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Username or Email"
              addon-left-icon="ni ni-email-83"
              v-model:value="model.email"
              name="email"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model:value="model.password"
              name="password"
            >
            </base-input>

            <div v-if="newPasswordNeeded">
              <div class="text-center text-muted mb-4">
                <small>New Password Needed</small>
              </div>
              <base-input
                formClasses="input-group-alternative mb-3"
                placeholder="New Password"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
                v-model:value="model.newPassword"
                name="newPassword"
              />
              <base-input
                formClasses="input-group-alternative mb-3"
                placeholder="New Password Confirm"
                type="password"
                addon-left-icon="ni ni-lock-circle-open"
                v-model:value="model.newPasswordConfirm"
                name="newPasswordConfirm"
              />
            </div>

            <div class="text-left" v-if="authenticateError.length > 0">
              <p class="text-danger"><small>{{ authenticateError }}</small></p>
            </div>

            <!--base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox-->
            <div class="text-center">
              <base-button native-type="submit" type="primary" class="my-4">Sign in</base-button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="#/forgot" class="text-light"><small>Forgot password?</small></a>
        </div>
        <!--div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div-->
      </div>
    </div>
  </div>
</template>
<script>
import auth from '../auth';
import router from '../router';

export default {
  name: "login",
  data() {
    return {
      model : {
        email: "",
        password: "",
        newPassword: "",
        newPasswordConfirm: "",
      },
      authenticateError : "",
      newPasswordNeeded : false
    };
  },
  methods: {
    signin: function(event) {
      var email = event.target.elements.email.value
      var password = event.target.elements.password.value

      if (this.newPasswordNeeded) {
        var newPassword = event.target.elements.newPassword.value;
        var newPasswordConfirm = event.target.elements.newPasswordConfirm.value;
        if (newPassword != newPasswordConfirm) {
          this.authenticateError = "passwords do not match";
          return;
        }
        auth.completeNewPasswordChallenge(newPasswordConfirm).then((result) => {
          auth.init();
          router.push('/');
        }).catch(err => {
          this.authenticateError = err.message;
        })
      } else {
        auth.authenticate(email, password).then((result) => {
          if (result && 'idToken' in result) {
              auth.init();
              router.push('/');
          } else {
            // need a new password
            this.newPasswordNeeded = true;
          }
        }).catch(err => {
          console.log("authenticate error", err);
          this.authenticateError = err.message;
        })
      }
    },
  }
};
</script>
<style></style>
